import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import {
  AuthUserContext,
  withAuthorization,
} from '../components/Session';
import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import PasswordChangeForm from '../components/PasswordChange';
import HeadData from "../data/HeadData"

const AccountPageBase = () => (
  <DashboardWrapper pageName='Account'>
    <div className="relative md:pt-28 2xl:pt-32 pb-32 pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <AuthUserContext.Consumer>
          {authUser => (
            <div className='w-full max-w-sm'>
              <h2 className='text-lg mb-4'><b>Your Name:</b> {authUser.name}</h2>
              <h2 className='text-lg mb-4'><b>Your Role:</b> {authUser.roles.ADMIN ? "ADMIN" : 'User'}</h2>
              <h2 className='text-lg mb-4'><b>Your Email:</b> {authUser.email}</h2>

              <h2 className='text-lg mb-2 font-bold mt-8'>Change Password</h2>
              <PasswordChangeForm />

            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const AccountPage = compose(
  withAuthorization(condition),
)(AccountPageBase);

const Account = () => (
  <Layout>
    <HeadData title='Your Account' />
    <AccountPage />
  </Layout>
)

export default Account
