import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <form onSubmit={this.onSubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
        <div className="mb-4">
          <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="passwordOne">
            Password
          </label>

          <input
            aria-label="passwordOne"
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
            className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-6">
          <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="passwordTwo">
            Confirm Password
          </label>

          <input
            aria-label="passwordTwo"
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
            className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <button disabled={isInvalid} type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo active:bg-red-900 transition duration-150 ease-in-out">
          Reset My Password
        </button>

        {error && <p className="font-body text-red-500 text-xs italic mt-6">{error.message}</p>}
      </form>
    );
  }
}

export default withFirebase(PasswordChangeForm);
